<template>
  <v-container>
    <v-row v-if="ready">
      <spinner style="margin: 300px 500px;"></spinner>
    </v-row>

    <v-container fluid grid-list-md v-else>
      <div class="ml-3 mb-n2">
        <v-breadcrumbs
          :items="items"
          :to="items.link"
          divider=" - "
        ></v-breadcrumbs>
      </div>
      <v-dialog v-model="dialog" width="400px">
        <v-form ref="form">
          <v-card>
            <v-card-title>
              <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
                >Assign Pump</span
              >
              <v-spacer></v-spacer>
              <v-progress-linear
                v-show="progress"
                class="mx-6"
                indeterminate
                color="cyan"
              ></v-progress-linear>
            </v-card-title>
            <v-card-actions>
              <v-spacer
                ><v-form ref="form" class="px-3">
                  <v-col>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-select
                          dense
                          :items="attendants"
                          v-model="attendantId"
                          ref="attendant"
                          label="Attendants"
                          outlined
                          :rules="[rules.requiredAttendants]"
                          class="mx-2 px-2 my-n4 required"
                        ></v-select>
                      </v-col>

                      <!--                    <v-text-field-->
                      <!--                      dense-->
                      <!--                      outlined-->
                      <!--                      label="Description"-->
                      <!--                      ref="description"-->
                      <!--                      class="mx-5 px-2 required"-->
                      <!--                      v-model="description"-->
                      <!--                      :rules="[rules.requiredDes]"-->
                      <!--                    >-->
                      <!--                    </v-text-field-->
                      <!--                  >-->
                    </v-row>
                    <v-row>
                      <!--                  <v-col cols="12" sm="12" md="12" lg="12"-->
                      <!--                    ><v-select-->
                      <!--                      dense-->
                      <!--                      :items="regions"-->
                      <!--                      v-model="region"-->
                      <!--                      ref="region"-->
                      <!--                      label="Region"-->
                      <!--                      outlined-->
                      <!--                      :rules="[rules.requiredRegion]"-->
                      <!--                      class="mx-2 px-2 my-n4 required"-->
                      <!--                    ></v-select-->
                      <!--                  ></v-col>-->

                      <!--                  <v-text-field-->
                      <!--                    dense-->
                      <!--                    outlined-->
                      <!--                    label="Latitude"-->
                      <!--                    class="mx-5 px-2 required"-->
                      <!--                    ref="lat"-->
                      <!--                    v-model="lat"-->
                      <!--                    :rules="[rules.requiredLat]"-->
                      <!--                  >-->
                      <!--                  </v-text-field>-->
                      <!--                  <v-text-field-->
                      <!--                    dense-->
                      <!--                    outlined-->
                      <!--                    label="Longitude"-->
                      <!--                    class="mx-5 px-2 required"-->
                      <!--                    ref="lng"-->
                      <!--                    v-model="lng"-->
                      <!--                    :rules="[rules.requiredLng]"-->
                      <!--                  >-->
                      <!--                  </v-text-field>-->
                    </v-row>
                  </v-col>
                  <v-row>
                    <v-spacer />
                    <v-btn
                      small
                      class=" px-1 mt-n5 mb-4 ml-10"
                      text
                      @click="dialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="name === null"
                      small
                      color="blue"
                      class="  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                      @click.stop="add"
                    >
                      Save
                    </v-btn>
                  </v-row>
                </v-form></v-spacer
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-layout row wrap>
        <v-flex xs12 sm5 class="ml-7"
          ><v-card outlined height="400" width="400" class="blue-grey--text">
            <div
              style="display: flex; flex-direction: column; justify-content: space-around; align-content: space-between; align-items: center"
            >
              <v-progress-linear
                v-show="progress"
                class="mx-6"
                indeterminate
                color="cyan"
              ></v-progress-linear>
              <div class="mb-4 mt-4">
                <h3>Pump information</h3>
              </div>

              <div
                style="display: flex; flex-direction: row; justify-content: space-around; padding: 10px 10px"
              >
                <div style="width: 200px"><h5>Name:</h5></div>
                <div style="width: 100px">
                  <h5>{{ details.name }}</h5>
                </div>
              </div>
              <div
                style="display: flex; flex-direction: row; justify-content: space-around; padding: 10px 10px"
              >
                <!--                <div style="width: 200px"><h5>Quantity:</h5></div>-->
                <!--                <div style="width: 100px">-->
                <!--                  <h5>{{ details.quantity }}</h5>-->
                <!--                </div>-->
              </div>
              <!--              <div-->
              <!--                style="display: flex; flex-direction: row; justify-content: space-around; padding: 10px 10px"-->
              <!--              >-->
              <!--                <div style="width: 200px"><h5>Total:</h5></div>-->
              <!--                <div style="width: 100px"><h5>GHS 300.00</h5></div>-->
              <!--              </div>-->
            </div>

            <div
              style="margin-top: 180px"
              class="d-flex flex-wrap justify-space-between mb-6"
            >
              <div></div>
              <div>
                <v-btn
                  class="mb-2"
                  small
                  depressed
                  color="success"
                  @click="openDialogForAssignment()"
                >
                  Assign Pump
                </v-btn>
              </div>
            </div>
          </v-card></v-flex
        >
        <v-flex xs12 sm6 class="ml-7">
          <v-card outlined height="400" width="700">
            <div style="display: flex; flex-direction: column">
              <div class="ml-3"><PageTitle text="Pump Attendants" /></div>
              <div>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-left"
                          style="font-weight: bold; font-size: 17px"
                        >
                          Name
                        </th>
                        <th
                          class="text-left"
                          style="font-weight: bold; font-size: 17px"
                        >
                          Phone Number
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">Abena Osei</td>
                        <td>0245612130</td>
                      </tr>
                      <tr>
                        <td>Michael Kwame</td>
                        <td>0246718908</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
          </v-card>
        </v-flex>
      </v-layout></v-container
    >
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import * as axios from "axios";
import Spinner from "vue-simple-spinner";
import moment from "moment";
export default {
  name: "PumpDetails",
  components: { PageTitle, spinner: Spinner },
  data: () => ({
    details: {},
    ready: true,
    ok: "",
    status: "",
    dialog: false,
    rules: {
      requiredAttendants: value =>
        !!value || value === null || "Attendant is required."
    },
    showSnackBar: false,
    message: "",
    attendantId: "",
    attendants: [],
    progress: false,
    marked: false,
    items: [
      {
        text: "Pumps",
        disabled: false,
        name: "master.pumps"
      },
      {
        text: "Pump-Details",
        disabled: false
      }
    ]
  }),

  filters: {
    format(value) {
      return moment(value)
        .subtract(0, "days")
        .calendar();
    }
  },

  methods: {
    getPump() {
      let id = this.$route.params.id;
      console.log(id);
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/pumps/${id}`
      })
        .then(response => {
          console.log(response);
          this.ready = false;
          if (response.status === 200) {
            this.details = response.data;
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$router.replace({ name: "/login" });
          } else {
            //console.log(err);
          }
        });
    },
    assignPump() {
      axios({
        method: "post",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/pumps/assign/attendants`,
        data: {
          attendantId: this.attendantId
        }
      })
        .then(response => {
          if (response.status === 200) {
            this.message = response.message;
          }
        })
        .catch(err => {
          this.progress = true;
          if (err.response.status === 401) {
            this.$router.replace({ name: "/login" });
          } else {
            //console.log(err);
          }
        });
    },

    openDialogForAssignment() {
      this.dialog = true;
      this.getUsers();
    },

    getUsers() {
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/pump-attendants`
      })
        .then(response => {
          if (response.status === 200) {
            this.attendants = response.data;
          }
        })
        .catch(err => {
          this.progress = true;
          if (err.response.status === 401) {
            this.$router.replace({ name: "/login" });
          } else {
            //console.log(err);
          }
        });
    }
  },
  created() {
    this.getPump();
  }
};
</script>

<style scoped></style>
